import * as React from "react";
import { Viewport, ViewSmall, ViewLarge, ViewExtraLarge, ViewMedium } from "@shared-ui/viewport-context";
import { withStores } from "stores";
import { PageHeadingProps } from "./typings";
import { EGDSHeading, EGDSText, EGDSHeadingProps } from "@egds/react-core/text";
import { EGDSLayoutFlex } from "@egds/react-core/layout-flex";
import { EGDSSpacing } from "@egds/react-core/spacing";
import { observer } from "mobx-react";
import { getFmId } from "src/stores/ExperienceTemplateStore";

const PageHeading = withStores("compositionStore")(
  observer((props: PageHeadingProps) => {
    const { compositionStore, templateComponent } = props;
    const { config } = templateComponent;
    const { view, showPageSubHeader } = config;
    const { pageHeading, pageSubHeadline } = compositionStore;
    const fmId = getFmId(templateComponent);
    const fmTitleId = config.fmTitleId ? `${config.fmTitleId}` : null;

    if (!pageHeading) {
      return null;
    }

    const shouldShowSubHeadline = Boolean(
      pageSubHeadline && (typeof showPageSubHeader === "undefined" || showPageSubHeader)
    );

    const pageHeadingView = (
      <>
        <Viewport>
          <ViewSmall>
            <EGDSHeading data-testid="dxPageHeading" tag="h1" size={2}>
              {pageHeading}
            </EGDSHeading>
          </ViewSmall>
          <ViewLarge>
            <EGDSHeading data-testid="dxPageHeading" tag="h1" size={1}>
              {pageHeading}
            </EGDSHeading>
          </ViewLarge>
        </Viewport>

        {shouldShowSubHeadline && (
          <EGDSText size={400} weight="regular" spacing="one">
            {pageSubHeadline}
          </EGDSText>
        )}
      </>
    );

    const flightsCommonView = (pageHeadingSize: EGDSHeadingProps["size"]) => {
      return (
        <EGDSSpacing
          padding={{ small: { blockend: "four" }, medium: { blockend: "unset" }, inline: "three", blockstart: "four" }}
        >
          <div className="PageHeading" data-fm={fmId} data-fm-title-id={fmTitleId}>
            <EGDSHeading tag="h1" size={pageHeadingSize}>
              {pageHeading}
            </EGDSHeading>
            {shouldShowSubHeadline && (
              <EGDSText size={300} spacing="three">
                {pageSubHeadline}
              </EGDSText>
            )}
          </div>
        </EGDSSpacing>
      );
    };

    switch (view) {
      case "hidden":
        return (
          <EGDSHeading tag="h1" className="sr-only">
            {pageHeading}
          </EGDSHeading>
        );
      case "destination-discovery":
        return (
          <Viewport>
            <ViewSmall>
              <EGDSSpacing padding="three">
                <EGDSLayoutFlex direction="column">{pageHeadingView}</EGDSLayoutFlex>
              </EGDSSpacing>
            </ViewSmall>
            <ViewLarge>
              <EGDSLayoutFlex className="SimpleContainer">
                <EGDSSpacing padding="three">
                  <EGDSLayoutFlex direction="column">{pageHeadingView}</EGDSLayoutFlex>
                </EGDSSpacing>
              </EGDSLayoutFlex>
            </ViewLarge>
          </Viewport>
        );
      case "affinity-launch":
        return (
          <EGDSLayoutFlex
            direction="column"
            className="PageHeadingAffinityLaunch"
            data-testid="affinityLaunchPageHeading"
          >
            <EGDSHeading tag="h1" size={2}>
              {pageHeading}
            </EGDSHeading>
            {shouldShowSubHeadline && <EGDSText spacing="one">{pageSubHeadline}</EGDSText>}
          </EGDSLayoutFlex>
        );
      case "search-form": {
        return (
          <Viewport>
            <ViewSmall>
              <EGDSSpacing padding={{ inline: "three", block: "three" }}>
                <EGDSHeading tag="h1" size={3}>
                  {pageHeading}
                </EGDSHeading>
              </EGDSSpacing>
            </ViewSmall>
            <ViewExtraLarge>
              <EGDSSpacing padding={{ block: "three" }}>
                <EGDSHeading tag="h1" size={3}>
                  {pageHeading}
                </EGDSHeading>
              </EGDSSpacing>
            </ViewExtraLarge>
          </Viewport>
        );
      }
      case "dateless-pdp": {
        return (
          <Viewport>
            <ViewSmall>
              <EGDSSpacing padding={{ inline: "three", block: "three" }}>
                <EGDSHeading tag="h2" size={4}>
                  {pageHeading}
                </EGDSHeading>
              </EGDSSpacing>
            </ViewSmall>
            <ViewExtraLarge>
              <EGDSSpacing padding={{ inline: "twelve", blockstart: "three" }}>
                <EGDSHeading tag="h2" size={3}>
                  {pageHeading}
                </EGDSHeading>
              </EGDSSpacing>
            </ViewExtraLarge>
          </Viewport>
        );
      }
      case "landing-pdp": {
        return (
          <EGDSSpacing padding={{ inline: "three", blockend: "six", blockstart: "six" }} margin={{ blockend: "two" }}>
            <EGDSHeading tag="h1" size={3}>
              {pageHeading}
            </EGDSHeading>
          </EGDSSpacing>
        );
      }
      case "search-form-h2": {
        return (
          <Viewport>
            <ViewSmall>
              <EGDSSpacing padding={{ inline: "three" }}>
                <EGDSHeading tag="h2" size={5}>
                  {pageHeading}
                </EGDSHeading>
              </EGDSSpacing>
            </ViewSmall>
            <ViewMedium>
              <EGDSSpacing padding={{ inline: "three" }}>
                <EGDSHeading tag="h2" size={5}>
                  {pageHeading}
                </EGDSHeading>
              </EGDSSpacing>
            </ViewMedium>
          </Viewport>
        );
      }
      case "flights": {
        return (
          <Viewport>
            <ViewLarge>{flightsCommonView(3)}</ViewLarge>
            <ViewMedium>{flightsCommonView(4)}</ViewMedium>
            <ViewSmall>{flightsCommonView(4)}</ViewSmall>
          </Viewport>
        );
      }
      default:
        return (
          <EGDSSpacing padding={{ inline: "three", blockstart: "six" }}>
            <div className="PageHeading" data-fm={fmId} data-fm-title-id={fmTitleId}>
              <EGDSHeading tag="h1" size={4}>
                {pageHeading}
              </EGDSHeading>
              {shouldShowSubHeadline && (
                <EGDSSpacing padding={{ blockstart: "one" }} margin={{ blockstart: "two" }}>
                  <EGDSText size={400}>{pageSubHeadline}</EGDSText>
                </EGDSSpacing>
              )}
            </div>
          </EGDSSpacing>
        );
    }
  })
);

export default PageHeading;
